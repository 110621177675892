<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div class="columns">
            <div class="column">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Menu Item Details</span>
              </div>
              <div class="block">
                <form @submit.prevent="handleFormSubmit">
                  <div>

                    <div v-if="error !== null" class="has-text-danger">
                      {{ error }}
                    </div>
                     <div class="field">
                      <label class="label is-size-7">
                        Item Type
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <label class="radio" style="width: 100%">
                          <input type="radio" name="ItemType" id="item-grouping" v-model="input.ItemType" value="1">
                          Constant Item Grouping
                        </label>
                        <label class="radio" style="width: 100%; margin-left: 0;">
                          <input type="radio" name="ItemType" id="item-grouping" v-model="input.ItemType" value="4">
                          Dynamic Item Grouping
                        </label>
                        <label class="radio" style="width: 100%; margin-left: 0;">
                          <input type="radio" name="ItemType" id="item-grouping" v-model="input.ItemType" value="6">
                          Vendor Grouping
                        </label>
                        <label class="radio" style="width: 100%; margin-left: 0;">
                          <input type="radio" name="ItemType" id="document-list" v-model="input.ItemType" value="2">
                          Document List
                        </label>
                        <label class="radio" style="width: 100%; margin-left: 0;">
                          <input type="radio" name="ItemType" id="page" v-model="input.ItemType" value="3">
                          Page
                        </label>
                      </div>
                    </div>
                    <div class="field" v-if="parseInt(input.ItemType) === 4">
                      <label class="label is-size-7">
                        Dynamic Item
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <Select :options="{ 1: 'Carrier List', 2: 'Plan List'}" :default="1" identifier="dynamicItem" @onchange="handleSelectChange"/>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Label
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.Label" :disabled="parseInt(input.ItemType) === 2"/>
                      </div>
                    </div>
                    <div class="field" v-if="parentOptions">
                      <label class="label is-size-7">
                        Display Under
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <SelectParentMenuItem ref="parentOptions" :options="parentOptions" :default="input.ParentId" identifier="parents" @onchange="handleSelectChange"/>
                      </div>
                    </div>
                    <div v-show="showReportOptions" class="field">
                      <label class="label is-size-7">
                        Report Type
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <Select ref="reportOptions" :options="reportOptions" :default="input.ReportCode" style="max-width: 50%" identifier="reportType" @onchange="handleSelectChange"/>
                      </div>
                    </div>
                    <div v-show="showPageOptions" class="field">
                      <label class="label is-size-7">
                        Destination
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <Select ref="pageOptions" :options="destinationOptions" :default="input.Route" style="max-width: 50%" identifier="destinations" @onchange="handleSelectChange"/>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Icon
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <Select ref="iconOptions" :options="iconOptions" :default="input.IconType" style="max-width: 50%" :disabled="isIconOptionsDisabled" identifier="icons" @onchange="handleSelectChange"/>
                      </div>
                    </div>
                  </div>
                  <div id="buttongroup" style="padding-top: 20px;">
                    <div class="field is-grouped">
                      <div class="control">
                        <button :disabled="!$hasPermissions(clientSession, ['CLIENT_MENU'], 2)" @click.prevent.stop="handleFormSubmit" class="button is-accent has-text-white" style="visibility: visible!important;">Save</button>
                      </div>
                      <div class="control">
                        <a class="button is-light" v-on:click="$router.back()">Cancel</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="column" style="max-width: 60%;">
              <!-- Reserved for future -->

              <!-- <pre v-html="menuItemJSON"></pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import Constants from '../constants.js'
import Select from './partials/Select.vue'
import SelectParentMenuItem from './partials/SelectParentMenuItem.vue'
import router from '../router.js'
import _ from 'underscore'

let pageName = 'Menu Item Edit'

export default {
  components: {
    Loading,
    Select,
    SelectParentMenuItem
  },
  props: {
    menuItem: {
      required: false,
      type: Object
    },
    id: [String, Number],
    reportTypes: Array
  },
  data () {
    return {
      isLoading: true,
      Constants: Constants,
      pageheading: pageName,
      showPageOptions: false,
      showReportOptions: false,
      input: this.menuItem,
      isIconOptionsDisabled: false,
      error: null,
      types: null,
      parentOptions: null
    }
  },
  async created () {
    if (this.input && this.input.ItemType === 3) {
      this.showPageOptions = true
    }

    if (this.menuItem === undefined || this.menuItem === null) {
      this.getMenuItem(this.$route.params.id)
      await this.getReportTypesFromAPI()
      await this.$store.dispatch('updateActiveSite')
    } else {
      this.types = this.reportTypes
      this.isLoading = false
    }

    await this.getParentOptions()
  },
  watch: {
    input: {
      handler (current, old) {
        this.itemTypeUpdated(current.ItemType)
      },
      deep: true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    menuItemJSON () {
      return JSON.stringify(this.input)
    },
    destinationOptions () {
      let options = {}

      router.options.routes.forEach(function (route, key) {
        if (route.exposeOptions) {
          options[route.name] = route.name
        }
      })

      options[0] = '(Select Destination)'

      return options
    },
    reportOptions () {
      let options = {}

      _.sortBy(this.types, 'name').forEach(function (type) {
        options[type.code] = `${type.name}`
      })

      options[0] = '(Select Report Type)'

      return options
    },
    iconOptions () {
      let that = this
      let options = {}

      Object.keys(this.Constants.menu.icon).map(function (key) {
        let icon = that.Constants.menu.icon[key]

        options[key] = `<div style="width: 25px;"><i class="${icon.value}" style="width: 20px;"></i></div><div> ${icon.name}</div>`
      })

      return options
    }
  },
  methods: {
    async getSiteNavigation () {
      try {
        let response = await this.axios.get(`/site`)
        return JSON.parse(response.data.siteNavigation)
      } catch (error) {
      } finally {
      }
    },
    async getParentOptions () {
      let that = this
      let options = []

      let response = (await this.getSiteNavigation())

      response.forEach(function (item) {
        if ((parseInt(item.ItemType) === 1 || parseInt(item.ItemType) === 4 || parseInt(item.ItemType) === 5 || parseInt(item.ItemType) === 6) && parseInt(item.Id) !== parseInt(that.input.Id) && parseInt(item.ParentId) !== parseInt(that.input.Id)) {
          options.push({
            Id: item.Id,
            Label: item.Label
          })
        }
      })

      options.push({
        Id: 0,
        Label: '(Top Level Item)'
      })

      this.parentOptions = _.sortBy(options, 'Label')
    },
    async getMenuItem (id) {
      let response = await this.axios.get('/sites/menu/' + id)

      if (response.status >= 200 && response.status < 300) {
        this.input = {
          Id: response.data.id,
          ParentId: response.data.parentId,
          ItemType: response.data.itemType,
          Label: response.data.label,
          IconType: response.data.iconType,
          ReportCode: response.data.reportCode,
          SiteId: response.data.siteId,
          Route: response.data.route,
          IsPersistent: response.data.IsPersistent,
          Order: response.data.order
        }
      }
    },
    async getReportTypesFromAPI () {
      let response = await this.axios.get('/report-types')

      if (response.status === 200) {
        this.types = response.data.records
      }

      this.isLoading = false
    },
    async handleFormSubmit () {
      let route = 'sites/menu'
      let response = null

      let request = {}

      if (this.input.Id) {
        request.id = this.input.Id
      }

      if (this.input.ParentId) {
        request.parentId = this.input.ParentId
      }

      if (this.input.ItemType) {
        request.itemType = this.input.ItemType
      }

      if (this.input.Label) {
        request.label = this.input.Label
      }

      if (this.input.IconType) {
        request.iconType = this.input.IconType
      }

      if (this.input.ReportCode && parseInt(this.input.ReportCode) !== 0) {
        request.reportCode = this.input.ReportCode
      }

      if (this.activeSite.id) {
        request.siteId = this.activeSite.id
      }

      if (this.input.Route) {
        request.route = this.input.Route
        // request.reportCode = 'USR'
      }

      if (this.input.Order) {
        request.order = this.input.Order
      } else {
        request.order = 0
      }

      if (this.input.Id === undefined) {
        response = await this.axios.post(route, request)
      } else if (this.input.Id !== undefined) {
        response = await this.axios.put(route, request)
      }

      if (response.status >= 200 && response.status < 300) {
        this.$router.push({ name: 'ClientMenuManagement' })
      } else {
        this.error = 'Unable to complete request. Error Code: CMIE001'
      }
    },
    handleSelectChange (select) {
      if (select.identifier === 'parents') {
        this.$set(this.input, 'ParentId', select.value)
      }

      if (select.identifier === 'reportType') {
        this.$set(this.input, 'ReportCode', select.value)
      }

      if (select.identifier === 'destinations') {
        this.$set(this.input, 'Route', select.value)
      }

      if (select.identifier === 'icons') {
        this.$set(this.input, 'IconType', select.value)
      }

      if (select.identifier === 'dynamicItem') {
        this.$set(this.input, 'itemSubType', select.value)
      }
    },
    itemTypeUpdated (ItemType) {
      if (parseInt(ItemType) === 1 || parseInt(ItemType) === 2) {
        this.showPageOptions = false
      } else if (parseInt(ItemType) === 3) {
        this.showPageOptions = true
        this.showReportOptions = false
        this.$refs.reportOptions.setSelected(0)
      }

      if (parseInt(ItemType) === 6) {
        this.showPageOptions = false
        this.showReportOptions = false
      }

      if (parseInt(ItemType) === 2) {
        this.input.Label = null
        this.isIconOptionsDisabled = false
        // this.$refs.icons.setSelected(2)
        this.showReportOptions = true
      } else {
        this.isIconOptionsDisabled = false
        this.showReportOptions = false
      }
    },
    getLabel (menuItem) {
      if (menuItem.ItemType === 1) {
        return menuItem.Label
      } else {
        let reportType = this.types.filter(function (type) {
          return type.code === menuItem.ReportCode
        })

        if (reportType.length === 1) {
          return `(${reportType[0].name})`
        }

        return '(N/A)'
      }
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
